import { useCallback, useEffect, useMemo, useState } from "react";
import manage from "../../../styles/admin.module.css"
import Moment from "react-moment";
import { Link, useNavigate, useParams } from "react-router-dom";
import NomalTable from "../table/NomalTable";
import { AdminRecordAxios, CommonAxios, CommonAxiosToken } from "../../../module/CommonAxios";
import { Cookies } from 'react-cookie'
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/locale";
import { FaCalendar } from "react-icons/fa";
import moment from "moment"
import SearchTable from "../table/SearchTable";
import { useDispatch, useSelector } from "react-redux";
import userSlice from "../../../slices/user";

import { LuArrowDownSquare, LuArrowUpSquare } from "react-icons/lu";
import { FaLocationDot } from "react-icons/fa6";
import { FaBus } from "react-icons/fa";
import { BiSolidPhoneCall, BiPhoneCall } from "react-icons/bi";
import { IoSettingsOutline } from "react-icons/io5";
import LineChart from "../../LineChart";
import PieChart from "../../PieChart";
import BarChart from "../../BarChart";


const LastBox = () => {
    const navigate = useNavigate();
    const [insertVale, setInsertValue] = useState("");

    const [data, setData] = useState([]);
    const [data1, setData1] = useState([]);
    const [data2, setData2] = useState([]);
    const dispatch = useDispatch();





    const [tokenSwitch, setTokenSwitch] = useState(false);

    const [swi, setSwi] = useState(false);
    let char = [
        {
            id: 1,
            label: "안드로이드",
            value: 0,
            "color": "hsl(106, 70%, 50%)"
        }, {
            id: 0,
            label: "아이폰",
            value: 0,
            "color": "hsl(106, 70%, 50%)"
        }
    ]
    let char1 = [
        {
            id: 0,
            label: "여성",
            value: 0,
            "color": "hsl(106, 70%, 50%)"
        }, {
            id: 1,
            label: "남성",
            value: 0,
            "color": "hsl(106, 70%, 50%)"
        }
    ]
    const getData = () => {


        let sendData = {

            where: "",
        };


        CommonAxiosToken(
            process.env.REACT_APP_HOSTDONAME + "/api/admin/main_userChart",
            sendData,
            function (result) {

                if (result.messageinfo) {



                    for (let i = 0; i < result.messageinfo.message.length; i++) {



                        let idIndex = char.findIndex((item) => {
                            return item.id === result.messageinfo.message[i].user_os
                        })
                        if (idIndex !== -1) {
                            if (result.messageinfo.message[i].user_os === 1) {
                                char[idIndex]["label"] = `안드로이드 ${result.messageinfo.message[i].PUCHASED_RATIO}%(${result.messageinfo.message[i].os}명)`;
                                char[idIndex]["value"] = result.messageinfo.message[i].os
                            } else {
                                char[idIndex]["label"] = `아이폰 ${result.messageinfo.message[i].PUCHASED_RATIO}%(${result.messageinfo.message[i].os}명)`
                                char[idIndex]["value"] = result.messageinfo.message[i].os
                            }
                        }

                    }
                    for (let i = 0; i < result.messageinfo.message_data2.length; i++) {



                        let idIndex = char1.findIndex((item) => {
                            return item.id === result.messageinfo.message_data2[i].user_gender
                        })
                        if (idIndex !== -1) {
                            if (result.messageinfo.message_data2[i].user_os === 0) {
                                char1[idIndex]["label"] = `여성 ${result.messageinfo.message_data2[i].PUCHASED_RATIO}%(${result.messageinfo.message_data2[i].gender}명)`;
                                char1[idIndex]["value"] = result.messageinfo.message_data2[i].gender
                            } else {
                                char1[idIndex]["label"] = `남성 ${result.messageinfo.message_data2[i].PUCHASED_RATIO}%(${result.messageinfo.message_data2[i].gender}명)`
                                char1[idIndex]["value"] = result.messageinfo.message_data2[i].gender
                            }
                        }

                    }
                    for (let i = 0; i < result.messageinfo.message_data3.length; i++) {



                        let idIndex = char3.findIndex((item) => {
                            return item.age === result.messageinfo.message_data3[i].age_group
                        })
                        console.log(idIndex);
                        if (idIndex !== -1) {
                            if (result.messageinfo.message_data3[i].age_group === "70대 이상") {
                                char3[idIndex]["age"] = `70대 이상 ${result.messageinfo.message_data3[i].total_cnt}명`;
                                char3[idIndex]["value"] = result.messageinfo.message_data3[i].total_cnt
                            } else if (result.messageinfo.message_data3[i].age_group === "60대") {
                                char3[idIndex]["age"] = `60대 ${result.messageinfo.message_data3[i].total_cnt}명`;
                                char3[idIndex]["value"] = result.messageinfo.message_data3[i].total_cnt
                            } else if (result.messageinfo.message_data3[i].age_group === "50대") {
                                char3[idIndex]["age"] = `50대 ${result.messageinfo.message_data3[i].total_cnt}명`;
                                char3[idIndex]["value"] = result.messageinfo.message_data3[i].total_cnt
                            } else if (result.messageinfo.message_data3[i].age_group === "40대") {
                                char3[idIndex]["age"] = `40대 ${result.messageinfo.message_data3[i].total_cnt}명`;
                                char3[idIndex]["value"] = result.messageinfo.message_data3[i].total_cnt
                            } else if (result.messageinfo.message_data3[i].age_group === "30대") {
                                char3[idIndex]["age"] = `30대 ${result.messageinfo.message_data3[i].total_cnt}명`;
                                char3[idIndex]["value"] = result.messageinfo.message_data3[i].total_cnt
                            } else if (result.messageinfo.message_data3[i].age_group === "20대") {
                                char3[idIndex]["age"] = `20대 ${result.messageinfo.message_data3[i].gender}명`;
                                char3[idIndex]["value"] = result.messageinfo.message_data3[i].total_cnt
                            } else {
                                char3[idIndex]["age"] = `10대 ${result.messageinfo.message_data3[i].total_cnt}명`;
                                char3[idIndex]["value"] = result.messageinfo.message_data3[i].total_cnt
                            }
                        }

                    }
                    setSwi(true);
                    console.log(char3);
                    // }
                    setData(char);
                    setData1(char1);
                    setData2(char3);
                } else {
                    // const options2 = {
                    //     method: "POST",
                    //     headers: {
                    //         Accept: "application/json",
                    //         "Content-Type": "application/json;charset=UTP-8",


                    //     },

                    // };
                    // if (result.data.message === "만료된 액세스 토큰입니다." || result.data.message === "유효하지 않은 액세스 토큰입니다.") {
                    //     axios(process.env.REACT_APP_HOSTDONAME + "/api/refreshToken", options2)
                    //         .then((response) => {
                    //             if (response.data.message === "실패") {
                    //                 dispatch(
                    //                     userSlice.actions.reset({})
                    //                 )
                    //                 navigate("/")
                    //             }
                    //             setTokenSwitch(true);

                    //         }).catch((error) => {
                    //             dispatch(
                    //                 userSlice.actions.reset({})
                    //             )
                    //             navigate("/")
                    //         })
                    // }

                }

            })
    }
    useEffect(() => {


        getData();

    }, [])

    const user = useSelector(state => state.user);


    const [arr, setArr] = useState([]);






    // useEffect(() => {

    //     if (arr.length > 0) {
    //         getData();
    //     }
    // }, [arr])

    const item = [
        {
            "id": "lisp",
            "label": "lisp",
            "value": 156,
            "color": "hsl(106, 70%, 50%)"
        },
        {
            "id": "stylus",
            "label": "stylus",
            "value": 267,
            "color": "hsl(108, 70%, 50%)"
        },
        {
            "id": "php",
            "label": "php",
            "value": 436,
            "color": "hsl(310, 70%, 50%)"
        },
        {
            "id": "elixir",
            "label": "elixir",
            "value": 283,
            "color": "hsl(145, 70%, 50%)"
        },
        {
            "id": "sass",
            "label": "sass",
            "value": 579,
            "color": "hsl(162, 70%, 50%)"
        }
    ];
    const char3 = [
        {
            age: "70대 이상",
            value: 0
        },
        {
            age: "60대",
            value: 0
        },
        {
            age: "50대",
            value: 0
        },
        {
            age: "40대",
            value: 0
        },
        {
            age: "30대",
            value: 0
        },
        {
            age: "20대",
            value: 0
        },
        {
            age: "10대",
            value: 0
        },
    ]
    const item2 = [
        {
            "country": "AD",
            "hot dog": 146,
            "hot dogColor": "hsl(332, 70%, 50%)",
            "burger": 132,
            "burgerColor": "hsl(306, 70%, 50%)",
            "sandwich": 124,
            "sandwichColor": "hsl(322, 70%, 50%)",
            "kebab": 73,
            "kebabColor": "hsl(191, 70%, 50%)",
            "fries": 53,
            "friesColor": "hsl(317, 70%, 50%)",
            "donut": 62,
            "donutColor": "hsl(134, 70%, 50%)"
        },
        {
            "country": "AE",
            "hot dog": 169,
            "hot dogColor": "hsl(294, 70%, 50%)",
            "burger": 104,
            "burgerColor": "hsl(335, 70%, 50%)",
            "sandwich": 138,
            "sandwichColor": "hsl(349, 70%, 50%)",
            "kebab": 104,
            "kebabColor": "hsl(216, 70%, 50%)",
            "fries": 29,
            "friesColor": "hsl(305, 70%, 50%)",
            "donut": 168,
            "donutColor": "hsl(209, 70%, 50%)"
        },
        {
            "country": "AF",
            "hot dog": 129,
            "hot dogColor": "hsl(43, 70%, 50%)",
            "burger": 134,
            "burgerColor": "hsl(215, 70%, 50%)",
            "sandwich": 142,
            "sandwichColor": "hsl(176, 70%, 50%)",
            "kebab": 163,
            "kebabColor": "hsl(120, 70%, 50%)",
            "fries": 21,
            "friesColor": "hsl(310, 70%, 50%)",
            "donut": 29,
            "donutColor": "hsl(265, 70%, 50%)"
        },
        {
            "country": "AG",
            "hot dog": 9,
            "hot dogColor": "hsl(65, 70%, 50%)",
            "burger": 135,
            "burgerColor": "hsl(102, 70%, 50%)",
            "sandwich": 0,
            "sandwichColor": "hsl(174, 70%, 50%)",
            "kebab": 155,
            "kebabColor": "hsl(237, 70%, 50%)",
            "fries": 141,
            "friesColor": "hsl(77, 70%, 50%)",
            "donut": 164,
            "donutColor": "hsl(202, 70%, 50%)"
        },
        {
            "country": "AI",
            "hot dog": 0,
            "hot dogColor": "hsl(144, 70%, 50%)",
            "burger": 16,
            "burgerColor": "hsl(314, 70%, 50%)",
            "sandwich": 23,
            "sandwichColor": "hsl(357, 70%, 50%)",
            "kebab": 97,
            "kebabColor": "hsl(326, 70%, 50%)",
            "fries": 77,
            "friesColor": "hsl(134, 70%, 50%)",
            "donut": 61,
            "donutColor": "hsl(113, 70%, 50%)"
        },
        {
            "country": "AL",
            "hot dog": 83,
            "hot dogColor": "hsl(247, 70%, 50%)",
            "burger": 39,
            "burgerColor": "hsl(123, 70%, 50%)",
            "sandwich": 45,
            "sandwichColor": "hsl(92, 70%, 50%)",
            "kebab": 80,
            "kebabColor": "hsl(130, 70%, 50%)",
            "fries": 197,
            "friesColor": "hsl(161, 70%, 50%)",
            "donut": 137,
            "donutColor": "hsl(328, 70%, 50%)"
        },
        {
            "country": "AM",
            "hot dog": 70,
            "hot dogColor": "hsl(256, 70%, 50%)",
            "burger": 45,
            "burgerColor": "hsl(347, 70%, 50%)",
            "sandwich": 79,
            "sandwichColor": "hsl(161, 70%, 50%)",
            "kebab": 97,
            "kebabColor": "hsl(14, 70%, 50%)",
            "fries": 34,
            "friesColor": "hsl(71, 70%, 50%)",
            "donut": 63,
            "donutColor": "hsl(74, 70%, 50%)"
        }
    ]
    return <div className={manage.admin_box}>
        <div className={manage.admin_box_inner}>
            <div className={manage.admin_box_top}>
                <h3>사용자 앱 현황</h3>
                <div>

                </div>
            </div>
            <div className={manage.admin_box_middle} style={{ height: "300px", display: "flex" }}>
                {/* dd */}
                {/* {swi && arr.length > 0 && <LineChart data={data23} />} */}
                <div style={{ width: "33%" }}>
                    {swi && data.length > 0 && <PieChart data={data} />}
                </div>
                <div style={{ width: "33%" }}>
                    {swi && data1.length > 0 && <PieChart data={data1} />}
                </div>
                <div style={{ width: "33%" }}>
                    {swi && data2.length > 0 && <BarChart data={data2} />}
                </div>
            </div>

        </div>
    </div >
}

export default LastBox