import { useCallback, useEffect, useMemo, useState } from "react";
import line from "../../../styles/busmg.module.css"
import { AdminRecordAxios, CommonAxios, CommonAxiosToken } from "../../../module/CommonAxios";
import InfopTable from "../table/InfopTable";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import userSlice from "../../../slices/user";
import moment from "moment"
import { useAsyncDebounce } from "react-table"; import { FaArrowLeftLong } from "react-icons/fa6";
import NomalTable1 from "../table/NomalTable1";
import Moment from "react-moment";
import NomalTable2 from "../table/NomalTable2";
const CompanyInfoWrap = () => {
    const { idx } = useParams()
    const navigate = useNavigate();
    const user = useSelector(state => state.user);

    const [code, setCode] = useState("");
    const [company, setCompany] = useState("");
    const [name, setName] = useState("");
    const [mobile, setMobile] = useState("");
    const [bus, setBus] = useState([]);
    const [number, setNumber] = useState([]);
    const [driver, setDriver] = useState([]);
    const changeCode = useCallback((e) => {
        setCode(e.target.value);
    }, [])
    const changeCompany = useCallback((e) => {
        setCompany(e.target.value);
    }, [])
    const changeName = useCallback((e) => {
        setName(e.target.value);
    }, [])
    const changeMobile = useCallback((e) => {
        setMobile(e.target.value);
    }, [])

    const savecompany = () => {
        console.log(idx)
        if (idx === "0") {


            let sendData = {
                com_name: company,
                com_code: code,
                com_admin_name: name,
                com_admin_tell: mobile,

            };
            let record = {
                record_category: "운수사",
                record_user: user.uniq,
                record_text: `''${code}''라는 운수사를 등록하였습니다.`,
                record_date: moment().format("YYYY-MM-DD HH:mm:ss"),
            }
            CommonAxios(
                process.env.REACT_APP_HOSTDONAME + "/api/admin/insert_company",
                sendData,
                function (result) {
                    if (result.result) {
                        AdminRecordAxios(record)
                        window.alert("등록되었습니다.");
                        navigate(`/busmg/companymg/companyInfo/${result.result}`)
                    } else {
                        window.alert("중복된 운수사 코드입니다.")
                    }

                }




            );

        }

    }

    const removecompany = () => {
        const result = window.confirm("해당 문의를 삭제하시겠습니까?");
        if (result) {
        }
        let sendData = {

        }
    }
    const dataView = () => {
        let sendData = {
            code: idx
        };

        CommonAxios(
            process.env.REACT_APP_HOSTDONAME + "/api/admin/company_detail_info",
            sendData,
            function (result) {
                if (result.messageinfo) {
                    setCode(result.messageinfo.message[0].com_code)
                    setCompany(result.messageinfo.message[0].com_name)
                    setName(result.messageinfo.message[0].com_admin_name)
                    setMobile(result.messageinfo.message[0].com_admin_tell)
                    setNumber(result.messageinfo.message_data2[0]);
                    setBus(result.messageinfo.message_data2[1]);
                    setDriver(result.messageinfo.message_data2[2]);
                }
            })

    }
    useEffect(() => {



        if (idx !== "0") {

            dataView();
        }
    }, [])







    const btn_save = () => {

        let sendData = {
            no: idx,
            // company_answer: answer,
            company_complete_date: moment().format("YYYY-MM-DD HH:mm:ss"),


        }

        CommonAxiosToken(
            process.env.REACT_APP_HOSTDONAME + "/api/admin/company_answer_update",
            sendData,
            function (result) {
                window.alert("답변이 등록되었습니다.")

            })


    }



    const columns = useMemo(() => [



        {
            Header: "버스번호",
            accessor: "bus_number",

        },

        {
            Header: "등록된 차량",
            accessor: "cnt",

        },
    ]);
    const columns2 = useMemo(() => [



        {
            Header: "차량번호",
            accessor: "list_car_number",

        },

        {
            Header: "버스번호",
            accessor: "bus_number",

        },
    ]);
    const columns3 = useMemo(() => [



        {
            Header: "기사명",
            accessor: "user_name",

        },

        {
            Header: "연락처",
            accessor: "user_mobile",

        }, {
            Header: "등록일",
            accessor: "user_reg_date",
            Cell: ({ cell: { value } }) => <Date value={value} />,
        },

    ]);

    //accessor 스타일링
    const Date = ({ value }) => {
        return (<>{value === null ? "" : <Moment format="YYYY-MM-DD HH:mm:ss">{value}</Moment>}</>)
    };
    const insert_bus = () => {
        if (idx === "0") return window.alert("운수사를 먼저 등록해 주세요.");
        setBusMo(true);
        setType("insert");
    }
    const insert_car = () => {
        if (idx === "0") return window.alert("운수사를 먼저 등록해 주세요.")
        if (number.length === 0) return window.alert("버스 번호를 먼저 등록해 주세요.")
        setCarMo(true);
        setType("insert");
    }
    const insert_driver = () => {
        if (idx === "0") return window.alert("운수사를 먼저 등록해 주세요.")
        setUserMo(true);
        setType("insert");
    }
    const [dataUniq, setDataUniq] = useState("");
    const [busMo, setBusMo] = useState(false);
    const [carMo, setCarMo] = useState(false);
    const [userMo, setUserMo] = useState(false);
    const [sqlType, setType] = useState("insert");
    const [addBus, setAddBus] = useState("");
    const [addCar, setAddCar] = useState("");
    const [addCarNo, setAddCarNo] = useState(0);
    const [driverName, setDriverName] = useState("");
    const [driverMobile, setDriverMobile] = useState("");

    const onAddBus = useCallback((e) => {

        setAddBus(e.target.value)
    }, [])
    const saveAddBus = (data) => {
        let sendData = {
            bus_company_no: idx,
            bus_number: addBus,
            type: sqlType,
            no: "",
            // company_answer: answer,
            bus_date: moment().format("YYYY-MM-DD HH:mm:ss"),


        }
        let record = {
            record_category: "운수사",
            record_user: user.uniq,
            record_text: `운수사 코드 ''${code}''의 버스를 추가하였습니다.`,
            record_date: moment().format("YYYY-MM-DD HH:mm:ss"),
        }
        if (data === "update") {

            record.record_text = `운수사 코드 ''${code}''의 버스를 수정하였습니다.`;
            sendData.no = dataUniq

        }

        CommonAxiosToken(
            process.env.REACT_APP_HOSTDONAME + "/api/admin/insert_company_bus",
            sendData,
            function (result) {

                dataView();
                AdminRecordAxios(record);
                setBusMo(false);
                setAddBus("");

                // window.alert("답변이 등록되었습니다.")

            }
        )

    }

    const closeModal = () => {

        setBusMo(false);
        setCarMo(false);
        setUserMo(false);
        setDataUniq("");
        setAddBus("");
        setAddCar("");
        setAddCarNo(0);
        setDriverName("");
        setDriverMobile("");
    }


    const saveAddCar = (data) => {
        let sendData = {
            list_company_no: idx,
            list_bus_no: addCarNo,
            list_car_number: addCar,
            type: sqlType,
            no: "",
            // company_answer: answer,
            bus_date: moment().format("YYYY-MM-DD HH:mm:ss"),


        }
        let record = {
            record_category: "운수사",
            record_user: user.uniq,
            record_text: `운수사 코드 ''${code}''의 차량을 추가하였습니다.`,
            record_date: moment().format("YYYY-MM-DD HH:mm:ss"),
        }
        if (data === "update") {

            record.record_text = `운수사 코드 ''${code}''의 차량을 수정하였습니다.`;
            sendData.no = dataUniq

        }

        CommonAxiosToken(
            process.env.REACT_APP_HOSTDONAME + "/api/admin/insert_company_car",
            sendData,
            function (result) {

                dataView();
                AdminRecordAxios(record);
                setCarMo(false);
                setAddCar("");
                setAddCarNo(0);

                // window.alert("답변이 등록되었습니다.")

            }
        )

    }
    const onAddCar = useCallback((e) => {

        setAddCar(e.target.value)
    }, [])
    const changeSelect = (e) => {
        setAddCarNo(e.target.value);
    }




    const saveAddUser = (data) => {
        let sendData = {
            user_name: driverName, user_mobile: driverMobile, user_type: 4, user_id: driverMobile,
            type: sqlType,
            user_uniq: "",
            user_company: idx,
            // company_answer: answer,
            bus_date: moment().format("YYYY-MM-DD HH:mm:ss"),


        }
        let record = {
            record_category: "운수사",
            record_user: user.uniq,
            record_text: `운수사 코드 ''${code}''의 기사님을 추가하였습니다.`,
            record_date: moment().format("YYYY-MM-DD HH:mm:ss"),
        }
        if (data === "update") {

            record.record_text = `운수사 코드 ''${code}''의 기사님을 수정하였습니다.`;
            sendData.user_uniq = dataUniq

        }

        CommonAxiosToken(
            process.env.REACT_APP_HOSTDONAME + "/api/admin/insert_company_driver",
            sendData,
            function (result) {

                dataView();
                AdminRecordAxios(record);
                setUserMo(false);
                setDriverName("");
                setDriverMobile("");

                // window.alert("답변이 등록되었습니다.")

            }
        )

    }
    const onAddDriverName = useCallback((e) => {

        setDriverName(e.target.value)
    }, [])

    const onAddDriverMobile = useCallback((e) => {

        setDriverMobile(e.target.value)
    }, [])

    return (
        <>{busMo &&
            <div className={line.company_modal}>
                <div className={line.company_modal_flex}>
                    <div className={line.company_modal_inner}>
                        <div className={line.company_modal_content1}>
                            <p>버스 번호</p>
                            <input type="text" onChange={onAddBus} value={addBus} />
                        </div>
                        <div className={line.company_modal_btn}>
                            <button onClick={closeModal}>닫기</button>
                            {sqlType === "insert" && <button onClick={() => saveAddBus("insert")}>추가</button>}
                            {sqlType === "update" && <button onClick={() => saveAddBus("update")}>수정</button>}
                        </div>
                    </div>
                </div>
            </div>}
            {carMo &&
                <div className={line.company_modal}>
                    <div className={line.company_modal_flex}>
                        <div className={line.company_modal_inner}>
                            <div className={line.company_modal_content2}>
                                <p>차량</p>
                                <select onChange={changeSelect} value={addCarNo}>
                                    <option value="0">버스번호 선택</option>
                                    {number.length > 0 && number.map((data) => {
                                        return <option value={data.no}>{data.bus_number}</option>
                                    })}
                                </select>
                                <input type="text" onChange={onAddCar} value={addCar} />
                            </div>
                            <div className={line.company_modal_btn}>
                                <button onClick={closeModal}>닫기</button>
                                {sqlType === "insert" && <button onClick={() => saveAddCar("insert")}>추가</button>}
                                {sqlType === "update" && <button onClick={() => saveAddCar("update")}>수정</button>}
                            </div>
                        </div>
                    </div>
                </div>}
            {userMo &&
                <div className={line.company_modal}>
                    <div className={line.company_modal_flex}>
                        <div className={line.company_modal_inner}>
                            <div className={line.company_modal_content2}>
                                <p>기사</p>
                                <input type="text" onChange={onAddDriverName} value={driverName} placeholder="기사명" />
                                <input type="text" onChange={onAddDriverMobile} value={driverMobile} placeholder="연락처" />
                            </div>
                            <div className={line.company_modal_btn}>
                                <button onClick={closeModal}>닫기</button>
                                {sqlType === "insert" && <button onClick={() => saveAddUser("insert")}>추가</button>}
                                {sqlType === "update" && <button onClick={() => saveAddUser("update")}>수정</button>}
                            </div>
                        </div>
                    </div>
                </div>}
            <div className={line.company_content_top}>
                <div className={line.company_inner}>
                    <FaArrowLeftLong onClick={() => navigate(-1)} style={{ cursor: "pointer", marginRight: "5px" }} />
                    {idx === "0" &&
                        <div className={`${line.stop_menu_li}`}>버스 운수사 등록</div>
                    }
                    {idx !== "0" &&
                        <div className={`${line.stop_menu_li}`}>버스 운수사 상세</div>
                    }

                </div>
                {idx !== "0" && <div>
                    <button className={line.save_btn} onClick={savecompany} >저장</button><button className={line.cancel_btn} onClick={removecompany} >삭제</button></div>}
                {idx === "0" && <button className={line.save_btn} onClick={savecompany} >저장</button>}


            </div>
            <div className={line.company_info_wrap}>

                <div className={line.company_info_box}>

                    <div className={line.company_info_table}>


                        <div className={line.company_info_row}>
                            <span className={line.company_info_span}>운수사 코드</span>
                            <div className={line.company_info_input}>
                                <input type="text" onChange={changeCode} value={code.replace(/[^0-9]/g, "")} disabled={idx === "0" ? false : true} />
                            </div>
                        </div>
                        <div className={line.company_info_row}>
                            <span className={line.company_info_span}>운수사명</span>
                            <div className={line.company_info_input}>
                                <input type="text" onChange={changeCompany} value={company} />
                            </div>
                        </div>
                        <div className={line.company_info_row}>
                            <span className={line.company_info_span}>담당자명</span>
                            <div className={line.company_info_input}>
                                <input type="text" onChange={changeName} value={name} />
                            </div>
                        </div>
                        <div className={line.company_info_row}>
                            <span className={line.company_info_span}>담당자 연락처</span>
                            <div className={line.company_info_input}>
                                <input type="text" onChange={changeMobile} value={mobile.replace(/[^0-9]/g, "")} />
                            </div>
                        </div>


                    </div>



                </div>
                <div className={line.company_info_box2}>
                    <div>
                        <div style={{ display: "flex", justifyContent: "space-between", padding: "10px 0" }}> <p>버스 번호</p>
                            <button onClick={insert_bus}>추가</button></div>
                        <NomalTable2 columns={columns} data={number} type={"company_number"}
                            setType={setType}
                            setDataUniq={setDataUniq}
                            setBusMo={setBusMo}
                            setAddBus={setAddBus} setCarMo={setCarMo}
                            setAddCar={setAddCar}
                            setAddCarNo={setAddCarNo}
                            setDriverName={setDriverName}
                            setDriverMobile={setDriverMobile}
                            setUserMo={setUserMo} />

                    </div>
                    <div>
                        <div style={{ display: "flex", justifyContent: "space-between", padding: "10px 0" }}> <p>차량 관리</p>
                            <button onClick={insert_car}>추가</button></div>
                        <NomalTable2 columns={columns2} data={bus} type={"company_bus"}
                            setType={setType}
                            setDataUniq={setDataUniq}
                            setBusMo={setBusMo}
                            setAddBus={setAddBus}
                            setCarMo={setCarMo}
                            setAddCar={setAddCar}
                            setAddCarNo={setAddCarNo}
                            setDriverName={setDriverName}
                            setDriverMobile={setDriverMobile}
                            setUserMo={setUserMo}
                        />

                    </div>
                    <div>
                        <div style={{ display: "flex", justifyContent: "space-between", padding: "10px 0" }}> <p>기사 관리</p>
                            <button onClick={insert_driver}>추가</button></div>
                        <NomalTable2 columns={columns3} data={driver} type={"company_driver"}
                            setType={setType}
                            setDataUniq={setDataUniq}
                            setBusMo={setBusMo}
                            setAddBus={setAddBus} setCarMo={setCarMo}
                            setAddCar={setAddCar}
                            setAddCarNo={setAddCarNo}
                            setDriverName={setDriverName}
                            setDriverMobile={setDriverMobile}
                            setUserMo={setUserMo} />
                    </div>
                </div>

            </div></>)
}

export default CompanyInfoWrap