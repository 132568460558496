import { useCallback, useEffect, useMemo, useState } from "react";
import manage from "../../../styles/admin.module.css"
import Moment from "react-moment";
import { Link, useNavigate, useParams } from "react-router-dom";
import NomalTable from "../table/NomalTable";
import { AdminRecordAxios, CommonAxios, CommonAxiosToken } from "../../../module/CommonAxios";
import { Cookies } from 'react-cookie'
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/locale";
import { FaCalendar } from "react-icons/fa";
import moment from "moment"
import SearchTable from "../table/SearchTable";
import { useDispatch, useSelector } from "react-redux";
import userSlice from "../../../slices/user";

import { LuArrowDownSquare, LuArrowUpSquare } from "react-icons/lu";
import { FaLocationDot } from "react-icons/fa6";
import { FaBus } from "react-icons/fa";
import { BiSolidPhoneCall, BiPhoneCall } from "react-icons/bi";
import { IoSettingsOutline } from "react-icons/io5";
import LineChart from "../../LineChart";


const MiddleBusBox = () => {
    const navigate = useNavigate();
    const [insertVale, setInsertValue] = useState("");

    const [data, setData] = useState([]);

    const dispatch = useDispatch();





    const [tokenSwitch, setTokenSwitch] = useState(false);

    const [swi, setSwi] = useState(false);
    const getData = () => {


        let sendData = {

            where: "",
        };


        CommonAxiosToken(
            process.env.REACT_APP_HOSTDONAME + "/api/admin/main_callChart",
            sendData,
            function (result) {

                if (result.messageinfo) {
                    console.log(result.messageinfo.message)

                    // if (arr.length > 0) {
                    let arr2 = arr;
                    for (let i = 0; i < result.messageinfo.message.length; i++) {

                        // for(let j=0; j<arr.length; j++ ){
                        // if(result.messageinfo.message[i].dt === arr[j].x){
                        let idIndex = arr.findIndex((item) => { return item.x === result.messageinfo.message[i].dt })
                        console.log(idIndex);
                        if (idIndex !== -1) {
                            arr[idIndex]["y"] = result.messageinfo.message[i].cnt;

                        }
                        // setArr(arr.filter((item)=> item.x === result.messageinfo.message[i].dt?  ))
                        // }
                        // }
                    }
                    let arr3 = arr2;
                    for (let j = 0; j < arr2.length; j++) {
                        if (arr2[j].y === null) {
                            arr2[j]['y'] = 0;
                        }
                    }
                    setArr(arr2)
                    console.log("왜");
                    // data2[0].data = arr2;
                    setSwi(true);
                    // }
                    setData(result.messageinfo.message);
                } else {
                    // const options2 = {
                    //     method: "POST",
                    //     headers: {
                    //         Accept: "application/json",
                    //         "Content-Type": "application/json;charset=UTP-8",


                    //     },

                    // };
                    // if (result.data.message === "만료된 액세스 토큰입니다." || result.data.message === "유효하지 않은 액세스 토큰입니다.") {
                    //     axios(process.env.REACT_APP_HOSTDONAME + "/api/refreshToken", options2)
                    //         .then((response) => {
                    //             if (response.data.message === "실패") {
                    //                 dispatch(
                    //                     userSlice.actions.reset({})
                    //                 )
                    //                 navigate("/")
                    //             }
                    //             setTokenSwitch(true);

                    //         }).catch((error) => {
                    //             dispatch(
                    //                 userSlice.actions.reset({})
                    //             )
                    //             navigate("/")
                    //         })
                    // }

                }

            })
    }
    useEffect(() => {




    }, [])

    const user = useSelector(state => state.user);


    const [arr, setArr] = useState([]);



    const getDatesStartToLast = (startDate, lastDate) => {
        const result = [];
        console.log(startDate)
        while (startDate <= lastDate) {
            let a = startDate.toISOString().split('T')[0];
            let b = a.replaceAll("-", "/")
            result.push({ x: b, y: 0 });
            startDate.setDate(startDate.getDate() + 1);
        }
        console.log(result)
        setArr(result)

        return result;
    };

    let data23 = [
        {
            "id": "japan",
            "color": "hsl(122, 70%, 50%)",
            "data": arr,
        },

    ]

    useEffect(() => {
        getDatesStartToLast(new Date(new Date().setMonth(new Date().getMonth() - 1)), new Date())


    }, [])


    useEffect(() => {

        if (arr.length > 0) {
            getData();
        }
    }, [arr])
    return <div className={manage.admin_box}>
        <div className={manage.admin_box_inner}>
            <div className={manage.admin_box_top}>
                <h3>버스 콜 현황</h3>
                <div>

                </div>
            </div>
            <div className={manage.admin_box_middle} style={{ height: "300px" }}>
                {/* dd */}
                {swi && arr.length > 0 && <LineChart data={data23} />}
            </div>

        </div>
    </div >
}

export default MiddleBusBox