import { useCallback, useEffect, useMemo, useState } from "react";
import manage from "../../../styles/statisticsmg.module.css"
import Moment from "react-moment";
import { useNavigate, useParams } from "react-router-dom";
import NomalTable from "../table/NomalTable";
import { CommonAxios, CommonAxiosToken } from "../../../module/CommonAxios";
import { Cookies } from 'react-cookie'
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/locale";
import { FaCalendar } from "react-icons/fa";
import moment from "moment"
import SearchTable from "../table/SearchTable";
import { useDispatch } from "react-redux";
import userSlice from "../../../slices/user";
import RaceInfoWrap from "./RaceInfoWrap";
const AdminStatInner = () => {
    const navigate = useNavigate();
    const [routeData, setRouteData] = useState([]);
    const [userData, setUserData] = useState([])
    const { idx } = useParams()
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    // const [searchValue, setSearchValue] = useState("");
    const dispatch = useDispatch();
    const columns = useMemo(() => [

        {
            Header: "No",
            accessor: "ROWNUM",

        },

        {
            Header: "관리자",
            accessor: "user_name",
            // Cell: (row) => {
            //     let data = row.cell;
            //     let oriNo = row.cell.row.original;
            //     let thumbnail = row.cell.row.original.event_thumbnail;

            //     return (
            //         <>

            //             {oriNo.user_type === "1" ? oriNo.user_name : "-"}


            //         </>
            //     );
            // },
        },
        {
            Header: "내용",
            accessor: "record_text",
        },


        {
            Header: "시간",
            accessor: "record_date",
            Cell: ({ cell: { value } }) => <Date value={value} />,
        },


    ]);

    const [viewData, setViewData] = useState("");
    const ButtonData = ({ value }) => {
        const viewModal = () => {
            setClose(true);
            setViewData(value);
        }
        return <button onClick={viewModal}>
            보기
        </button>
    }

    //accessor 스타일링
    const Date = ({ value }) => {
        return <Moment format="YYYY.MM.DD HH:mm:ss">{value}</Moment>;
    };





    const [tokenSwitch, setTokenSwitch] = useState(false);
    const [callUser, setCallUser] = useState("0");
    const [callCa, setCallCa] = useState(0);
    const [callKind, setCallKind] = useState(1);
    const getData = () => {
        let where = ``;

        // if (callCa) {
        //     where += ` AND allocate_category='${callCa}' `
        // }

        // if (callUser !== "0") {
        //     where += ` AND allocate_user_no='${callUser}' `
        // }

        let sendData = {
            startDate: startDate, endDate: endDate ? endDate : moment().format("YYYY-MM-DD"), search: where,
        };

        CommonAxiosToken(
            process.env.REACT_APP_HOSTDONAME + "/api/admin/admin_static_table",
            sendData,
            function (result) {
                if (result.messageinfo) {


                    setRouteData(result.messageinfo.message);
                    setUserData(result.messageinfo.message_data2);

                } else {
                    const options2 = {
                        method: "POST",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json;charset=UTP-8",


                        },

                    };

                    if (result.data.message === "만료된 액세스 토큰입니다." || result.data.message === "유효하지 않은 액세스 토큰입니다.") {
                        axios(process.env.REACT_APP_HOSTDONAME + "/api/refreshToken", options2)
                            .then((response) => {
                                if (response.data.message === "실패") {
                                    dispatch(
                                        userSlice.actions.reset({})
                                    )
                                    navigate("/")
                                }
                                setTokenSwitch(true);

                            }).catch((error) => {
                                dispatch(
                                    userSlice.actions.reset({})
                                )
                                navigate("/")
                            })
                    }

                }

            })
    }
    useEffect(() => {


        getData();



    }, [tokenSwitch])


    const searchFun = () => {
        getData();
    }
    const resetFun = () => {
        setStartDate("")
        setEndDate("");
        setCallCa(0);
        setCallKind(1);
        setCallUser("0");
        // setSearchValue("");
        getData();
    }
    const changeSelect1 = (e) => {
        setCallUser(e.target.value);
    }
    const changeSelect2 = (e) => {
        setCallCa(e.target.value);
    }
    const changeSelect3 = (e) => {
        setCallKind(e.target.value);
    }
    const [close, setClose] = useState(false);
    return <div className={manage.manage_wrap}>

        <div className={manage.manage_box} style={{ position: "relative" }}>
            {close && <RaceInfoWrap setClose={setClose} setViewData={setViewData} viewData={viewData} close={close} />}
            {!close &&
                <div>
                    <div className={manage.manage_top}>
                        <span>통계</span>
                    </div>
                    <div className={manage.manage_middle} style={{ position: "relative" }}>

                        <div>
                            <div className={manage.mange_search_wrap}>
                                <div className={manage.manage_search_box}>

                                    <div className={manage.manage_search_inner}>
                                        <div className={manage.manage_date}>
                                            <label>기간</label>
                                            <span className={manage.manage_datepicker}>
                                                <span>
                                                    <DatePicker
                                                        dateFormat="yyyy-MM-dd"
                                                        locale={ko}
                                                        onChange={(date) => setStartDate(date)}
                                                        maxDate={endDate}
                                                        selected={startDate}
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        placeholderText={"YYYY.MM.DD"}
                                                    />
                                                </span>
                                                <span className={manage.datepicker_ico}>
                                                    <FaCalendar />
                                                </span>
                                            </span>
                                            <span>~</span>
                                            <span className={manage.manage_datepicker}>
                                                <span>
                                                    <DatePicker
                                                        dateFormat="yyyy-MM-dd"
                                                        locale={ko}
                                                        onChange={(date) => setEndDate(date)}
                                                        selected={endDate}
                                                        maxDate={window.Date}
                                                        startDate={endDate}
                                                        endDate={endDate}
                                                        placeholderText={"YYYY.MM.DD"}
                                                    />
                                                </span>
                                                <span className={manage.datepicker_ico}>
                                                    <FaCalendar />

                                                </span>
                                            </span>

                                        </div>
                                        <div className={manage.manage_select}>
                                            <select onChange={changeSelect1} value={callUser} >
                                                <option value="0">상담원 전체</option>
                                                {userData && userData.map((data) => {
                                                    return <option value={data.user_uniq}>{data.user_name}</option>
                                                })}
                                            </select>
                                        </div>
                                        <div className={manage.manage_select}>
                                            <select onChange={changeSelect2} value={callCa}>
                                                <option value={0}>콜분류 전체</option>
                                                <option value={1}>대표번호콜</option>
                                                <option value={2}>정류장콜</option>
                                                <option value={3}>앱콜</option>
                                            </select>
                                        </div>
                                        <div className={manage.manage_select}>
                                            <select onChange={changeSelect3} value={callKind}>

                                                <option value={1}>버스콜</option>
                                                <option value={2}>택시콜</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className={manage.manage_serach_button}>
                                        <button onClick={resetFun}>초기화</button>
                                        <button onClick={searchFun}>검색</button>
                                    </div>
                                </div>

                            </div>
                            {routeData && <SearchTable columns={columns} data={routeData} type={"stat"} />}
                        </div>
                    </div>
                </div>}
        </div ></div>
}

export default AdminStatInner