import {
    useTable,
    useRowSelect,
    usePagination,
    useSortBy,
    useGlobalFilter,
} from "react-table";
import manage from "./table.module.css"
import { useNavigate } from "react-router-dom";
import { FaFileExcel } from "react-icons/fa6";
import XLSX from 'xlsx-js-style';
import { AdminRecordAxios } from "../../../module/CommonAxios";
import { useSelector } from "react-redux";
import moment from "moment";
const SearchTable = ({ columns, data, length, type }) => {
    const user = useSelector(state => state.user);
    const prePage = () => {
        previousPage();
    };
    const nexPage = () => {
        nextPage();
    };

    const navigate = useNavigate();

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page, // Instead of using 'rows', we'll use page
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        selectedFlatRows,
        state: { pageIndex, pageSize },
        setGlobalFilter,
        globalFilter,
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 },
        },
        useGlobalFilter,
        useSortBy,
        usePagination,
        useRowSelect,
        // (hooks) => {
        //     hooks.visibleColumns.push((columns) => [
        //         // Let's make a column for selection
        //         {
        //             id: "selection",
        //             // getToggleAllPageRowsSelectedProps : 페이지에 보여지는 row 전체 선택
        //             // getToggleAllRowsSelectedProps : 모든 row 전체 선택
        //             Header: ({ getToggleAllPageRowsSelectedProps }) => (
        //                 <div>
        //                     <IndeterminateCheckbox
        //                         {...getToggleAllPageRowsSelectedProps()}
        //                     />
        //                 </div>
        //             ),
        //             // The cell can use the individual row's getToggleRowSelectedProps method
        //             // to the render a checkbox
        //             Cell: ({ row }) => (
        //                 <div>
        //                     <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
        //                 </div>
        //             ),
        //         },
        //         ...columns,
        //     ]);
        // }
    );
    const move_data = (item) => {
        if (type === "stop") {
            navigate(`/busmg/stopmg/stopinfo/${item.stop_number}`)
        } if (type === "qna") {
            navigate(`/appmg/qnamg/qnaInfo/${item.no}`)
        } if (type === 'bus') {

        }
        if (type === 'user') {
            navigate(`/appmg/usermg/userInfo/${item.user_uniq}`)
        }
    }


    const excelDown = async () => {
        try {

            const wb = XLSX.utils.book_new();
            const headerStyle = {
                font: { bold: true, color: { rgb: '000000' }, name: '함초롱바탕', sz: 13 },
                // fill: { fgColor: { rgb: 'BC8F8F' } },
                alignment: { horizontal: 'center', vertical: 'center' },
                border: { left: { style: 'thin', color: { auto: 1 } }, right: { style: 'thin', color: { auto: 1 } }, top: { style: 'thin', color: { auto: 1 } }, bottom: { style: 'thin', color: { auto: 1 } } }
            };

            const dataStyle = {
                font: { color: { rgb: '000000' }, name: '함초롱바탕', sz: 11 },
                fill: { fgColor: { rgb: 'FFFAFA' } },
                alignment: { horizontal: 'center', vertical: 'center' },
                border: { left: { style: 'thin', color: { auto: 1 } }, right: { style: 'thin', color: { auto: 1 } }, top: { style: 'thin', color: { auto: 1 } }, bottom: { style: 'thin', color: { auto: 1 } } }
            };
            const colWidths = [40, 80, 80, 80, 80, 80, 80, 80];
            const cols = colWidths.map(width => ({ wpx: width }));
            const headerRow = [
                { v: 'No', t: 's', s: headerStyle },
                { v: 'IDX', t: 's', s: headerStyle },
                { v: '가입잀 ', t: 's', s: headerStyle },
                { v: '가입단말', t: 's', s: headerStyle },
                { v: '회원전화번호', t: 's', s: headerStyle },
                { v: '성별', t: 's', s: headerStyle },
                { v: '나이', t: 's', s: headerStyle },
                { v: '탈퇴일자', t: 's', s: headerStyle },
            ];

            const dataRows = data.map(emp => [
                { v: emp.ROWNUM, t: 's', s: dataStyle },  // 사원번호
                { v: emp.user_uniq, t: 's', s: dataStyle },  // 사원명
                { v: emp.user_reg_date, t: 's', s: dataStyle },  // 현황
                { v: emp.user_os === 1 ? "안드로이드폰" : "아이폰", t: 's', s: dataStyle },  // 직급
                { v: emp.user_mobile.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`), t: 's', s: dataStyle },  // 전화번호
                { v: emp.user_gender === 1 ? "남" : "여", t: 's', s: dataStyle },
                { v: emp.user_age, t: 's', s: dataStyle },
                { v: emp.user_delete_date ? emp.user_delete_date : "", t: 's', s: dataStyle },
            ]);
            const rows = [headerRow, ...dataRows];
            const ws = XLSX.utils.aoa_to_sheet(rows);

            // cols 속성 적용
            ws['!cols'] = cols;

            // workbook에 추가
            XLSX.utils.book_append_sheet(wb, ws, '유저 목록');

            // 파일 다운로드
            XLSX.writeFile(wb, 'user.xlsx');
            let record = {
                record_category: "회원정보",
                record_user: user.uniq,
                record_text: `회원정보 엑셀을 다운받았습니다.`,
                record_date: moment().format("YYYY-MM-DD HH:mm:ss"),
            }
            AdminRecordAxios(record)
        } catch (e) {

        } finally { }
    }


    const excelDown2 = async () => {
        try {

            const wb = XLSX.utils.book_new();
            const headerStyle = {
                font: { bold: true, color: { rgb: '000000' }, name: '함초롱바탕', sz: 13 },
                // fill: { fgColor: { rgb: 'BC8F8F' } },
                alignment: { horizontal: 'center', vertical: 'center' },
                border: { left: { style: 'thin', color: { auto: 1 } }, right: { style: 'thin', color: { auto: 1 } }, top: { style: 'thin', color: { auto: 1 } }, bottom: { style: 'thin', color: { auto: 1 } } }
            };

            const dataStyle = {
                font: { color: { rgb: '000000' }, name: '함초롱바탕', sz: 11 },
                fill: { fgColor: { rgb: 'FFFAFA' } },
                alignment: { horizontal: 'center', vertical: 'center' },
                border: { left: { style: 'thin', color: { auto: 1 } }, right: { style: 'thin', color: { auto: 1 } }, top: { style: 'thin', color: { auto: 1 } }, bottom: { style: 'thin', color: { auto: 1 } } }
            };
            const colWidths = [40, 80, 80, 80, 80, 80, 80, 80];
            const cols = colWidths.map(width => ({ wpx: width }));
            const headerRow = [
                { v: 'No', t: 's', s: headerStyle },
                { v: '정류장명', t: 's', s: headerStyle },
                { v: '정류장번호 ', t: 's', s: headerStyle },
                { v: '방면정보', t: 's', s: headerStyle },
                { v: '화상연결', t: 's', s: headerStyle },
                { v: '등록일', t: 's', s: headerStyle },

            ];

            const dataRows = data.map(emp => [
                { v: emp.ROWNUM, t: 's', s: dataStyle },  // 사원번호
                { v: emp.stop_name, t: 's', s: dataStyle },  // 사원명
                { v: emp.stop_number, t: 's', s: dataStyle },  // 현황
                { v: emp.stop_direction, t: 's', s: dataStyle },  // 직급
                { v: emp.stop_connection === 1 ? "설치" : "미설치", t: 's', s: dataStyle },  // 전화번호
                { v: moment(emp.stop_date).format("YYYY-MM-DD HH:mm:ss"), t: 's', s: dataStyle },

            ]);
            const rows = [headerRow, ...dataRows];
            const ws = XLSX.utils.aoa_to_sheet(rows);

            // cols 속성 적용
            ws['!cols'] = cols;

            // workbook에 추가
            XLSX.utils.book_append_sheet(wb, ws, '정류장 목록');

            // 파일 다운로드
            XLSX.writeFile(wb, 'busstop.xlsx');
            let record = {
                record_category: "정류장 관리",
                record_user: user.uniq,
                record_text: `정류장 관리 엑셀을 다운받았습니다.`,
                record_date: moment().format("YYYY-MM-DD HH:mm:ss"),
            }
            AdminRecordAxios(record)
        } catch (e) {

        } finally { }
    }

    return (
        <>


            <div className={manage.manage_table_box}>
                <div className={manage.manage_search_div}>
                    <div><span>목록</span>
                        {type !== "stat" && <span>검색결과 {length ? length : "0"}</span>}
                    </div>
                    {type === "user" && <div style={{ cursor: "pointer" }} className={manage.manage_search_excel} onClick={excelDown}>
                        <FaFileExcel /><span>엑셀다운로드</span>
                    </div>}
                    {type === "stop" && <div style={{ cursor: "pointer" }} className={manage.manage_search_excel} onClick={excelDown2}>
                        <FaFileExcel /><span>엑셀다운로드</span>
                    </div>}
                    {type === "record" && <div style={{ cursor: "pointer" }} className={manage.manage_search_excel} onClick={excelDown2}>
                        <FaFileExcel /><span>엑셀다운로드</span>
                    </div>}
                    {/* {type ==="stat"} */}
                </div>
                <table className={manage.manage_table} {...getTableProps()}>
                    <thead className={manage.manage_thead}>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        {column.render("Header")}
                                        <span>
                                            {column.isSorted
                                                ? column.isSortedDesc
                                                    ? " 🔽"
                                                    : " 🔼"
                                                : ""}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody className={manage.manage_tbody} {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr style={{ cursor: "pointer" }} {...row.getRowProps()} onClick={() => move_data(row.original)} >
                                    {row.cells.map((cell) => {
                                        return (
                                            <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <div className={manage.manage_table_bottom} >

                    <div >
                        {pageIndex >= 10 && (
                            <button
                                onClick={() => gotoPage(Math.floor(pageIndex / 10) * 10 - 1)}
                            >
                                {"<"}
                            </button>
                        )}
                        {pageOptions.map((page, index) => {
                            if (pageIndex < 10 && index < 10) {
                                return (
                                    <button
                                        key={index}
                                        onClick={() => gotoPage(index)}
                                        style={{
                                            fontWeight: pageIndex === index ? "bold" : "normal",
                                            // color: pageIndex === index ? "#fff" : "#000",
                                            // background:
                                            //     pageIndex === index ? "rgb(0, 199, 60)" : "#f5f5f5",

                                            width: "30px",
                                            height: "30px",
                                            borderRadius: "5px",
                                        }}
                                    >
                                        {index + 1}
                                    </button>
                                );
                            } else if (pageIndex >= 10) {
                                const currentPageGroup = Math.floor(pageIndex / 10); // 현재 페이지 그룹 번호 계산
                                const startPageIndex = currentPageGroup * 10; // 현재 페이지 그룹의 첫 번째 페이지 인덱스
                                const endPageIndex = Math.min(startPageIndex + 10, pageCount); // 현재 페이지 그룹의 마지막 페이지 인덱스
                                if (index >= startPageIndex && index < endPageIndex) {
                                    return (
                                        <button
                                            key={index}
                                            onClick={() => gotoPage(index)}
                                            style={{
                                                fontWeight: pageIndex === index ? "bold" : "normal",



                                                width: "30px",
                                                height: "30px",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            {index + 1}
                                        </button>
                                    );
                                }
                            }
                            return null;
                        })}
                        {Math.floor(pageIndex / 10) < Math.floor(pageCount / 10) && (
                            <button
                                onClick={() => gotoPage(Math.floor(pageIndex / 10 + 1) * 10)}
                            >
                                {">"}
                            </button>
                        )}
                    </div>

                </div>  </div>
        </>
    );
}
export default SearchTable;