
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AdminRecordAxios, CommonAxios } from "../module/CommonAxios";
import MapWrap from "../components/manage/busmg/RouteMapWrap";
import line from "../styles/operate.module.css"
import { FaArrowLeftLong } from "react-icons/fa6";
import RouteInfoWrap from "../components/manage/busmg/RouteInfoWrap";
import StopInfoWrap from "../components/manage/busmg/StopInfoWrap";
import { useSelector } from "react-redux";
import moment from "moment";
import CallInfoWrap from "../components/manage/operatemg/CallInfoWrap";
const OperateCallInfo = () => {
    const navigate = useNavigate();
    const { idx } = useParams();
    const user = useSelector(state => state.user);
    const remove = () => {
        const result = window.confirm("해당 정류장을 삭제하시겠습니까?");
        if (result) {

            let sendData = {
                stop_number: idx,
            };
            let record = {
                record_category: "정류장 관리",
                record_user: user.uniq,
                record_text: `''${idx}''라는 정류장을 삭제하였습니다.`,
                record_date: moment().format("YYYY-MM-DD HH:mm:ss"),
            }
            CommonAxios(
                process.env.REACT_APP_HOSTDONAME + "/api/admin/stop_delete",
                sendData,
                function (result) {
                    AdminRecordAxios(record);
                    window.alert("삭제되었습니다.");
                    navigate(`/busmg/stopmg`);
                }




            );
        }

    }

    // useEffect(() => {



    //     // api 연동
    //     let sendData = {};
    //     CommonAxios(
    //         process.env.REACT_APP_HOSTDONAME + "/api/source",
    //         sendData,
    //         function (result) {
    //             if (result.messageinfo.state == "ok") {
    //             } else {

    //             }
    //         }
    //     );
    // }, [])

    const btn_insert = () => {



    }
    return (
        <>

            {/* <div className="common-wrap"> */}
            <div className={line.operate_info_wrap}>

                <div className={line.operate_info_box}>
                    <div className={line.operate_info_content}>
                        <div className={line.operate_info_content_top}>
                            <div className={line.operate_info_inner}>
                                <FaArrowLeftLong onClick={() => navigate(-1)} style={{ cursor: "pointer", marginRight: "5px" }} />
                                {idx === "0" &&
                                    <div className={`${line.operate_info_menu_li}`}>콜센터 담당자 등록</div>
                                }
                                {idx !== "0" &&
                                    <div className={`${line.operate_info_menu_li}`}>콜센터 담당자 수정</div>
                                }

                            </div>
                            {idx !== "0" && <button onClick={remove} >삭제</button>}


                        </div>
                        <CallInfoWrap />
                        {/* <MapWrap /> */}
                    </div>  </div>
            </div>
            {/* </div> */}
        </>
    )
}

export default OperateCallInfo