import { IoIosArrowForward } from "react-icons/io";
import { Link, useLocation } from "react-router-dom";

import menu from "./menu.module.css"
import { useEffect, useState } from "react";
const Menu = ({ type }) => {
    const location = useLocation();
    const [locat, setLocat] = useState("");
    useEffect(() => {
        let loca = location.pathname.split("/")
        setLocat(loca[loca.length - 1])

    }, [location])
    return <div className={menu.menu_wrap}><div className={menu.menu_box}>
        {type === 'user' &&
            <ul>

                <li><Link to="/appmg/qnamg" className={locat === "qnamg" ? `${menu.meun_a} ${menu.meun_a_color}` : menu.meun_a}>1:1 문의 <IoIosArrowForward /> </Link></li>
                <li><Link to="/appmg/usermg" className={locat === "usermg" ? `${menu.meun_a} ${menu.meun_a_color}` : menu.meun_a}>회원정보 <IoIosArrowForward /> </Link></li>
                <li><Link to="/appmg/noticemg" className={locat === "noticemg" ? `${menu.meun_a} ${menu.meun_a_color}` : menu.meun_a}>공지사항 <IoIosArrowForward /> </Link></li>
                {/* <li><Link to="/appmg/taxinoticemg" className={locat === "taxinoticemg" ? `${menu.meun_a} ${menu.meun_a_color}` : menu.meun_a}>공지사항(택시기사용) <IoIosArrowForward /> </Link></li> */}
                <li><Link to="/appmg/faqmg" className={locat === "faqmg" ? `${menu.meun_a} ${menu.meun_a_color}` : menu.meun_a}>FAQ <IoIosArrowForward /> </Link></li>
                <li><Link to="/appmg/divimg" className={locat === "divimg" ? `${menu.meun_a} ${menu.meun_a_color}` : menu.meun_a}>FAQ분류관리 <IoIosArrowForward /> </Link></li>
                <li><Link to="/appmg/termmg/1" className={locat === "termmg" ? `${menu.meun_a} ${menu.meun_a_color}` : menu.meun_a}>약관 <IoIosArrowForward /> </Link></li>
                {/* <li><Link to="/appmg/vermg" className={locat === "vermg" ? `${menu.meun_a} ${menu.meun_a_color}` : menu.meun_a}>앱버전 관리 <IoIosArrowForward /> </Link></li> */}



            </ul>}
        {type === 'operate' &&
            <ul>

                <li><Link to="/operatemg/call" className={locat === "call" ? `${menu.meun_a} ${menu.meun_a_color}` : menu.meun_a}>콜센터담당자관리 <IoIosArrowForward /> </Link></li>
                <li><Link to="/operatemg/admin" className={locat === "admin" ? `${menu.meun_a} ${menu.meun_a_color}` : menu.meun_a}>관제운영자관리 <IoIosArrowForward /> </Link></li>
                <li><Link to="/operatemg/moniter" className={locat === "moniter" ? `${menu.meun_a} ${menu.meun_a_color}` : menu.meun_a}>공공모니터링운영자관리 <IoIosArrowForward />  </Link></li>
                {/* <li><Link to="/operatemg/stop" className={locat === "stop" ? `${menu.meun_a} ${menu.meun_a_color}` : menu.meun_a}>정류장비밀번호관리 <IoIosArrowForward /> </Link></li>
                <li><Link to="/operatemg/number" className={locat === "number" ? `${menu.meun_a} ${menu.meun_a_color}` : menu.meun_a}>콜센터번호관리 <IoIosArrowForward /> </Link></li> */}
                {/* <li><Link to="/operatemg/call" className={locat === "termmg" ? `${menu.meun_a} ${menu.meun_a_color}` : menu.meun_a}>개인정보처리방침 <IoIosArrowForward /></Link></li> */}


            </ul>}
        {type === 'bus' &&
            <ul>

                <li><Link to="/busmg/stopmg" className={locat === "stopmg" ? `${menu.meun_a} ${menu.meun_a_color}` : menu.meun_a}>정류장 관리 <IoIosArrowForward /> </Link></li>
                <li><Link to="/busmg/routemg" className={locat === "routemg" ? `${menu.meun_a} ${menu.meun_a_color}` : menu.meun_a}>노선 관리 <IoIosArrowForward /> </Link></li>
                <li><Link to="/busmg/busmg" className={locat === "busmg" ? `${menu.meun_a} ${menu.meun_a_color}` : menu.meun_a}>버스 목록 <IoIosArrowForward />  </Link></li>
                <li><Link to="/busmg/companymg" className={locat === "companymg" ? `${menu.meun_a} ${menu.meun_a_color}` : menu.meun_a}>버스운수사 <IoIosArrowForward /> </Link></li>
                <li><Link to="/busmg/record" className={locat === "record" ? `${menu.meun_a} ${menu.meun_a_color}` : menu.meun_a}>주행 기록 <IoIosArrowForward /> </Link></li>
                {/* <li><Link to="/busmg/termmg" className={locat === "termmg" ? `${menu.meun_a} ${menu.meun_a_color}` : menu.meun_a}>개인정보처리방침 <IoIosArrowForward /></Link></li> */}


            </ul>}
        {type === "statistics" &&
            <ul>

                <li><Link to="/statistics/bus" className={locat === "bus" ? `${menu.meun_a} ${menu.meun_a_color}` : menu.meun_a}>버스 통계 <IoIosArrowForward /> </Link></li>
                {/* <li><Link to="/statistics/taxi" className={locat === "taxi" ? `${menu.meun_a} ${menu.meun_a_color}` : menu.meun_a}>택시 통계 <IoIosArrowForward /> </Link></li> */}
                <li><Link to="/statistics/call" className={locat === "call" ? `${menu.meun_a} ${menu.meun_a_color}` : menu.meun_a}>콜 통계 <IoIosArrowForward />  </Link></li>
                <li><Link to="/statistics/admin" className={locat === "admin" ? `${menu.meun_a} ${menu.meun_a_color}` : menu.meun_a}>관리자 기록 <IoIosArrowForward />  </Link></li>

            </ul>}
    </div> </div>
}

export default Menu