import { useCallback, useEffect, useState } from "react";
import line from "../../../styles/busmg.module.css"
import { AdminRecordAxios, CommonAxios } from "../../../module/CommonAxios";
import { LuArrowDownSquare, LuArrowUpSquare } from "react-icons/lu";
import mColor from "../../../assets/images/map_icon_color.png";
import m from "../../../assets/images/map_icon.png"
import XLSX from 'xlsx-js-style';
import { FaFileExcel } from "react-icons/fa6";
import { useSelector } from "react-redux";
import moment from "moment";

const { kakao } = window;
const RouteMapWrap = ({ idx }) => {
    const user = useSelector(state => state.user);

    // 검색시 나타나는 정류장
    const [searchStop, setSearchStop] = useState([]);
    const [routeStop, setRouteStop] = useState([]) //insert 한 노선 정류장;
    const [markerStop, setMarkerStop] = useState([]);
    const [routeLine, setRouteLine] = useState([]);
    const [kakaoLine, setKakaoLine] = useState([]);
    const [filterValue, setFilterValue] = useState("정류장번호순");
    const [fliterView, setFilterView] = useState(false);
    const [checkData, setCheckData] = useState([]);
    const [saveShow, setSaveShow] = useState(false);
    const [routeName, setRouteName] = useState("");

    const [sendCheckData, setSendCheckData] = useState([]);





    const [view, setView] = useState(false);
    const recentData = () => {
        let sendData = {
            no: idx
        };

        CommonAxios(
            process.env.REACT_APP_HOSTDONAME + "/api/admin/get_route",
            sendData,
            function (result) {
                if (result.result.length > 0) {
                    // let arr = [];

                    // 차집합
                    let arr = result.result2.filter(item => {
                        return !result.result.some(other => other.rs_stop_number === item.no)
                    })


                    setSearchStop(arr.sort((a, b) => a.stop_number.toLowerCase() < b.stop_number.toLowerCase() ? -1 : 1));
                    setRouteStop(result.result);


                    setSendCheckData([
                        {
                            no: result.result[result.result.length - 1].no,
                            rs_longitude: result.result[result.result.length - 1].stop_longitude,
                            rs_latitude: result.result[result.result.length - 1].stop_latitude,
                            rs_stop_number: result.result[result.result.length - 1].rs_stop_number,
                            rs_order: result.result[result.result.length - 1].rs_order,
                            stop_name: result.result[result.result.length - 1].stop_name,
                        }
                    ])
                } else {
                    setSearchStop(result.result2);
                }
                // console.log(result.result3[1].route_name)
                setRouteName(result.result3[1][0].route_name)
                if (result.result3[0].length > 0) {


                    const result5 = result.result3[0].reduce((acc, curr) => {  // [1]
                        const { rs_order } = curr;



                        if (acc[rs_order]) {
                            // if(curr.line_latitude === )

                            acc[rs_order].push(curr);

                        }  // [3]
                        else acc[rs_order] = [curr];              // [4]


                        if (acc) {
                            return acc
                        }
                        // return acc;                           // [5]
                    }, []);


                    const resultDelete = result5.filter(el => el)



                    let ar = [];
                    let br = [];
                    for (let i = 0; i < result.result.length; i++) {


                        if (result.result[i].stop_latitude === resultDelete[i][0].line_latitude &&
                            result.result[i].stop_longitude === resultDelete[i][0].line_longitude
                        ) {
                            // console.log(result.result[i].stop_latitude + ',' + resultDelete[i][0].line_latitude);
                            for (let j = 0; j < resultDelete[i].length; j++) {
                                ar.push(resultDelete[i][j]);
                            }
                            br.push(resultDelete[i][0])

                        } else {
                            for (let j = resultDelete[i].length - 1; j >= 0; j--) {
                                ar.push(resultDelete[i][j]);
                            }
                            br.push(resultDelete[i][resultDelete[i].length - 1])
                        }
                    }


                    setRouteLine(ar);


                    setMarkerStop(br);
                    let arr = [];
                    ar.map((data) => {

                        arr.push(new kakao.maps.LatLng(data.line_latitude, data.line_longitude))
                    })

                    setKakaoLine(arr);

                }


            });


    }
    useEffect(() => {
        recentData();



    }, [])

    const SearchItem = ({ item, index }) => {


        const [check, setCheck] = useState(false);

        const checkFun = (id) => {
            // setCheck(!check);
            const isChecked = checkData.includes(id);


            if (isChecked) {

                setCheckData((prev) => prev.filter((el) => el !== id));
            } else {

                setCheckData((prev) => [...prev, id]);
            }

        }

        return (<div key={index} className={line.line_modal_item} >

            <label>
                <input type="checkbox" onChange={() => checkFun(item.stop_number)} checked={checkData.includes(item.stop_number)} />
                <div className={checkData.includes(item.no) ? `${line.line_modal_check} ${line.line_check} ` : line.line_modal_check}>
                    <span>{item.stop_name}</span>
                    <span>({item.stop_number})</span>
                </div></label>
        </div >)
    }


    const Tbdata = ({ data, index }) => {

        const [timeValue, setTimeValue] = useState("");

        useEffect(() => {
            if (data) {
                setTimeValue(data.rs_time)
            }
        }, [])
        const downClick = (id, order, latitude, longitude, stop_no) => {

            let nextOrder = routeStop.filter((item) => item.rs_order === order + 1);
            let nextOrder2 = routeStop.filter((item) => item.rs_order === order + 2);
            let prevOrder = routeStop.filter((item) => item.rs_order === order - 1);

            let selectSql = "";
            let selectSql2 = "";
            let selectSql3 = "";
            let selectSql4 = "";

            if (nextOrder.length > 0) {
                selectSql = `where (detail_stop_no_one='${stop_no}' or detail_stop_no_two='${stop_no}') AND 
            (detail_stop_no_one='${nextOrder[0].rs_stop_number}' or detail_stop_no_two='${nextOrder[0].rs_stop_number}')`;
            }


            if (nextOrder2.length > 0) {
                selectSql2 = `where (detail_stop_no_one='${stop_no}' or detail_stop_no_two='${stop_no}') AND 
            (detail_stop_no_one='${nextOrder2[0].rs_stop_number}' or detail_stop_no_two='${nextOrder2[0].rs_stop_number}')`;
            } else {
                selectSql4 = `where (detail_stop_no_one='${stop_no}' or detail_stop_no_two='${stop_no}') AND 
                (detail_stop_no_one='' or detail_stop_no_two='')`;
            }
            if (prevOrder.length > 0) {
                selectSql3 = `where (detail_stop_no_one='${nextOrder[0].rs_stop_number}' or detail_stop_no_two='${nextOrder[0].rs_stop_number}') AND 
            (detail_stop_no_one='${prevOrder[0].rs_stop_number}' or detail_stop_no_two='${prevOrder[0].rs_stop_number}')`;
            }



            let sendData = {
                selectSql4: selectSql4,
                selectSql3: selectSql3,
                prevOrder: prevOrder.length > 0 ? prevOrder[0].rs_order : "",
                prevOrderNo: prevOrder.length > 0 ? prevOrder[0].no : "",
                prevLatitude: prevOrder.length > 0 ? prevOrder[0].stop_latitude : "",
                prevLongitude: prevOrder.length > 0 ? prevOrder[0].stop_longitude : "",
                prevStopNo: prevOrder.length > 0 ? prevOrder[0].rs_stop_number : "",



                selectSql: selectSql,
                nextOrder: nextOrder.length > 0 ? nextOrder[0].rs_order : "",
                nextOrderNo: nextOrder.length > 0 ? nextOrder[0].no : "",
                nextLatitude: nextOrder.length > 0 ? nextOrder[0].stop_latitude : "",
                nextLongitude: nextOrder.length > 0 ? nextOrder[0].stop_longitude : "",
                nextStopNo: nextOrder.length > 0 ? nextOrder[0].rs_stop_number : "",

                order: order,
                no: id,
                latitude: latitude,
                longitude: longitude,
                stopNo: stop_no,

                selectSql2: selectSql2,
                nextOrder2: nextOrder2.length > 0 ? nextOrder2[0].rs_order : "",
                nextOrderNo2: nextOrder2.length > 0 ? nextOrder2[0].no : "",
                nextLatitude2: nextOrder2.length > 0 ? nextOrder2[0].stop_latitude : "",
                nextLongitude2: nextOrder2.length > 0 ? nextOrder2[0].stop_longitude : "",
                nextStopNo2: nextOrder2.length > 0 ? nextOrder2[0].rs_stop_number : "",




            };
            let record = {
                record_category: "노선 관리",
                record_user: user.uniq,
                record_text: `''${routeName}''의 정류장 순서를 변경하였습니다.`,
                record_date: moment().format("YYYY-MM-DD HH:mm:ss"),
            }
            CommonAxios(
                process.env.REACT_APP_HOSTDONAME + "/api/admin/update_order",
                sendData,
                function (result) {
                    if (result.result) {
                        AdminRecordAxios(record)
                        recentData();
                    }



                })
        }
        const upClick = (id, order, latitude, longitude, stop_no) => {


            let nextOrder = routeStop.filter((item) => item.rs_order === order - 1);
            let nextOrder2 = routeStop.filter((item) => item.rs_order === order - 2);
            let prevOrder = routeStop.filter((item) => item.rs_order === order + 1);

            let selectSql = "";
            let selectSql2 = "";
            let selectSql3 = "";
            let selectSql4 = "";





            // return;


            if (nextOrder.length > 0) {
                selectSql = `where (detail_stop_no_one='${stop_no}' or detail_stop_no_two='${stop_no}') AND 
            (detail_stop_no_one='${nextOrder[0].rs_stop_number}' or detail_stop_no_two='${nextOrder[0].rs_stop_number}')`;
            }


            if (nextOrder2.length > 0) {
                selectSql2 = `where (detail_stop_no_one='${stop_no}' or detail_stop_no_two='${stop_no}') AND 
            (detail_stop_no_one='${nextOrder2[0].rs_stop_number}' or detail_stop_no_two='${nextOrder2[0].rs_stop_number}')`;
            }
            if (prevOrder.length > 0) {
                selectSql3 = `where (detail_stop_no_one='${nextOrder[0].rs_stop_number}' or detail_stop_no_two='${nextOrder[0].rs_stop_number}') AND 
            (detail_stop_no_one='${prevOrder[0].rs_stop_number}' or detail_stop_no_two='${prevOrder[0].rs_stop_number}')`;
            } else {
                selectSql4 = `where (detail_stop_no_one='${nextOrder[0].rs_stop_number}' or detail_stop_no_two='${nextOrder[0].rs_stop_number}') AND 
            (detail_stop_no_one='' or detail_stop_no_two='')`
            }



            let sendData = {
                selectSql4: selectSql4,
                selectSql3: selectSql3,
                prevOrder: prevOrder.length > 0 ? prevOrder[0].rs_order : "",
                prevOrderNo: prevOrder.length > 0 ? prevOrder[0].no : "",
                prevLatitude: prevOrder.length > 0 ? prevOrder[0].stop_latitude : "",
                prevLongitude: prevOrder.length > 0 ? prevOrder[0].stop_longitude : "",
                prevStopNo: prevOrder.length > 0 ? prevOrder[0].rs_stop_number : "",
                selectSql: selectSql,
                nextOrder: nextOrder.length > 0 ? nextOrder[0].rs_order : "",
                nextOrderNo: nextOrder.length > 0 ? nextOrder[0].no : "",
                nextLatitude: nextOrder.length > 0 ? nextOrder[0].stop_latitude : "",
                nextLongitude: nextOrder.length > 0 ? nextOrder[0].stop_longitude : "",
                nextStopNo: nextOrder.length > 0 ? nextOrder[0].rs_stop_number : "",
                order: order,
                no: id,
                latitude: latitude,
                longitude: longitude,
                stopNo: stop_no,
                selectSql2: selectSql2,
                nextOrder2: nextOrder2.length > 0 ? nextOrder2[0].rs_order : "",
                nextOrderNo2: nextOrder2.length > 0 ? nextOrder2[0].no : "",
                nextLatitude2: nextOrder2.length > 0 ? nextOrder2[0].stop_latitude : "",
                nextLongitude2: nextOrder2.length > 0 ? nextOrder2[0].stop_longitude : "",
                nextStopNo2: nextOrder2.length > 0 ? nextOrder2[0].rs_stop_number : "",



            };
            let record = {
                record_category: "노선 관리",
                record_user: user.uniq,
                record_text: `''${routeName}''의 정류장 순서를 변경하였습니다.`,
                record_date: moment().format("YYYY-MM-DD HH:mm:ss"),
            }
            CommonAxios(
                process.env.REACT_APP_HOSTDONAME + "/api/admin/update_order_up",
                sendData,
                function (result) {
                    if (result.result) {
                        AdminRecordAxios(record);
                        recentData();
                    }



                })
        }

        const essentFun = (ess, id) => {
            let sendData = {
                rs_stop_essential: ess === 1 ? 0 : 1,
                no: id
            }
            let record = {
                record_category: "노선 관리",
                record_user: user.uniq,
                record_text: `''${routeName}''의 정류장 정보를 변경하였습니다.`,
                record_date: moment().format("YYYY-MM-DD HH:mm:ss"),
            }
            CommonAxios(
                process.env.REACT_APP_HOSTDONAME + "/api/admin/update_essential",
                sendData,
                function (result) {
                    if (result.result) {
                        AdminRecordAxios(record);
                        recentData();
                    }



                })

        }

        const removeStop = (id, order, latitude, longitude, stop_no) => {



            let nextOrder = routeStop.filter((item) => item.rs_order === order - 1);

            let prevOrder = routeStop.filter((item) => item.rs_order === order + 1);




            let selectSql = "";
            let selectSql2 = "";
            let selectSql3 = "";
            if (nextOrder.length > 0 && prevOrder.length > 0) {
                selectSql = `where (detail_stop_no_one='${prevOrder[0].rs_stop_number}' or detail_stop_no_two='${prevOrder[0].rs_stop_number}') AND 
            (detail_stop_no_one='${nextOrder[0].rs_stop_number}' or detail_stop_no_two='${nextOrder[0].rs_stop_number}')`;
            }


            if (nextOrder.length > 0 && prevOrder.length === 0) {
                selectSql2 = `where (detail_stop_no_one='' or detail_stop_no_two='') AND 
            (detail_stop_no_one='${nextOrder[0].rs_stop_number}' or detail_stop_no_two='${nextOrder[0].rs_stop_number}')`;
            }

            if (nextOrder.length === 0 && prevOrder.length > 0) {
                selectSql3 = `where (detail_stop_no_one='${prevOrder[0].rs_stop_number}' or detail_stop_no_two='${prevOrder[0].rs_stop_number}') AND 
            (detail_stop_no_one='' or detail_stop_no_two='')`;
            }

            let sendData = {
                selectSql3: selectSql3,
                selectSql2: selectSql2,
                prevOrder: prevOrder.length > 0 ? prevOrder[0].rs_order : "",
                prevOrderNo: prevOrder.length > 0 ? prevOrder[0].no : "",
                prevLatitude: prevOrder.length > 0 ? prevOrder[0].stop_latitude : "",
                prevLongitude: prevOrder.length > 0 ? prevOrder[0].stop_longitude : "",
                prevStopNo: prevOrder.length > 0 ? prevOrder[0].rs_stop_number : "",
                selectSql: selectSql,
                nextOrder: nextOrder.length > 0 ? nextOrder[0].rs_order : "",
                nextOrderNo: nextOrder.length > 0 ? nextOrder[0].no : "",
                nextLatitude: nextOrder.length > 0 ? nextOrder[0].stop_latitude : "",
                nextLongitude: nextOrder.length > 0 ? nextOrder[0].stop_longitude : "",
                nextStopNo: nextOrder.length > 0 ? nextOrder[0].rs_stop_number : "",
                order: order,
                no: id,
                latitude: latitude,
                longitude: longitude,
                stopNo: stop_no,




            };

            let record = {
                record_category: "노선 관리",
                record_user: user.uniq,
                record_text: `''${routeName}''의 정류장을 삭제하였습니다.`,
                record_date: moment().format("YYYY-MM-DD HH:mm:ss"),
            }
            CommonAxios(
                process.env.REACT_APP_HOSTDONAME + "/api/admin/delete_route_stop",
                sendData,
                function (result) {
                    if (result.result) {
                        AdminRecordAxios(record);
                        recentData();
                    }



                })

        }
        const timeCall = useCallback(() => {

        }, [])


        const onTime = useCallback((e) => {


            if (/^[0-9]*$/.test(Number(e.target.value))) {

                setTimeValue(e.target.value)
            }
        }, [])

        const onTimeBlur = () => {

            let sendData = {
                no: data.no,
                routeNo: idx,
                value: timeValue,
            }
            let record = {
                record_category: "노선 관리",
                record_user: user.uniq,
                record_text: `''${routeName}''의 정류장 정보를 변경하였습니다.`,
                record_date: moment().format("YYYY-MM-DD HH:mm:ss"),
            }
            CommonAxios(
                process.env.REACT_APP_HOSTDONAME + "/api/admin/update_bus_time",
                sendData,
                function (result) {
                    if (result.result) {

                        AdminRecordAxios(record)
                        // recentData();
                        // window.alert("저장 완료");

                    }
                })

        }
        return <tr key={index}>
            <td className={line.info_td_first}>
                {index === 0 ?
                    <div style={{ display: "flex" }}>
                        <div style={{ cursor: "pointer" }} onClick={() => downClick(data.no, data.rs_order, data.stop_latitude, data.stop_longitude, data.rs_stop_number)}><LuArrowDownSquare /></div><div></div></div>
                    :
                    index + 1 === routeStop.length ?
                        <div style={{ display: "flex" }}><div></div>
                            <div style={{ cursor: "pointer" }} onClick={() => upClick(data.no, data.rs_order, data.stop_latitude, data.stop_longitude, data.rs_stop_number)}><LuArrowUpSquare /></div></div>
                        : <div style={{ display: "flex" }}>
                            <div style={{ cursor: "pointer" }} onClick={() => downClick(data.no, data.rs_order, data.stop_latitude, data.stop_longitude, data.rs_stop_number)}><LuArrowDownSquare /></div>
                            <div style={{ cursor: "pointer" }} onClick={() => upClick(data.no, data.rs_order, data.stop_latitude, data.stop_longitude, data.rs_stop_number)}><LuArrowUpSquare /></div></div>
                }</td>
            <td>{data.stop_name}</td>
            <td>{data.stop_number}</td>
            <td></td>
            <td style={{ width: "50px" }}>  <input type="text" value={timeValue} onChange={onTime} style={{ width: '40px', border: "1px solid #ccc" }} onBlur={onTimeBlur} /></td>
            <td><button onClick={() => {
                essentFun(data.rs_stop_essential, data.no)
            }} className={data.rs_stop_essential === 0 ? `${line.info_td_essential} ${line.essential_not}` : `${line.info_td_essential}`}>{data.rs_stop_essential === 0 ? "가변" : "필수"}</button></td>
            <td><button onClick={() => removeStop(data.no, data.rs_order, data.stop_latitude, data.stop_longitude, data.rs_stop_number)} className={line.info_td_remove}>삭제</button></td>
        </tr>
    }

    const insertStop = () => {
        let detailSql = "";
        let stopSql = "";

        let count = 0;

        let arr = [];

        if (sendCheckData.length > 0) {
            let orderCount = Number(sendCheckData[0].rs_order)






            arr.push(sendCheckData[0])
            checkData.map((data) => {

                searchStop.map((data2) => {

                    if (data === data2.stop_number) {
                        orderCount = orderCount + 1

                        arr.push({
                            no: "",
                            rs_longitude: data2.stop_longitude,
                            rs_latitude: data2.stop_latitude,
                            rs_stop_number: data2.stop_number,
                            rs_order: orderCount,
                            stop_name: data2.stop_name,

                        })
                        // setSendCheckData((item) => [...item, {
                        //     rs_longitude: data2.stop_longitude,
                        //     rs_latitude: data2.stop_latitude,
                        //     rs_stop_number: data2.stop_number,
                        //     rs_order: orderCount,
                        //     rs_name: data2.rs_name,

                        // }])

                        // stopSql += `('${data2.stop_number}','${data2.stop_name}','${count}'),`
                    }
                })

            })

            if (arr.length > 0) {

                let sendData = {
                    arr: arr,
                    routeNo: idx,
                    type: 1,
                }
                let record = {
                    record_category: "노선 관리",
                    record_user: user.uniq,
                    record_text: `''${routeName}''의 정류장을 추가하였습니다.`,
                    record_date: moment().format("YYYY-MM-DD HH:mm:ss"),
                }
                CommonAxios(
                    process.env.REACT_APP_HOSTDONAME + "/api/admin/insert_route_stop",
                    sendData,
                    function (result) {
                        if (result.result) {
                            AdminRecordAxios(record);
                            recentData();
                        }



                    })

            } else {
                window.alert("추가하신 정류장이 없습니다.");
            }


        } else {

            checkData.map((data) => {

                searchStop.map((data2) => {

                    if (data === data2.stop_number) {
                        count = count + 1

                        arr.push({
                            no: "",
                            rs_longitude: data2.stop_longitude,
                            rs_latitude: data2.stop_latitude,
                            rs_stop_number: data2.stop_number,
                            rs_order: count,
                            stop_name: data2.stop_name,

                        })
                    }
                })

            })

            if (arr.length > 0) {

                let sendData = {
                    arr: arr,
                    routeNo: idx,
                    type: 0,
                }
                let record = {
                    record_category: "노선 관리",
                    record_user: user.uniq,
                    record_text: `''${routeName}''의 정류장을 추가하였습니다.`,
                    record_date: moment().format("YYYY-MM-DD HH:mm:ss"),
                }
                CommonAxios(
                    process.env.REACT_APP_HOSTDONAME + "/api/admin/insert_route_stop",
                    sendData,
                    function (result) {
                        if (result.result) {
                            AdminRecordAxios(record);

                            recentData();
                        }



                    })

            } else {
                window.alert("추가하신 정류장이 없습니다.");
            }


        }






    }


    const MapCompents = ({ markerStop }) => {


        const [maps1, setMaps] = useState(null);             // 카카오 지도 데이터
        const [managers, setManagers] = useState(null);     // 카카오 선 그리기 용 데이터


        useEffect(() => {



            const container = document.getElementById('map');
            const saveBtn = document.getElementById('save');
            const options = {
                center: new kakao.maps.LatLng(36.809165585385955, 127.79723697515438),
                level: 7,
            }

            const map = new kakao.maps.Map(container, options);


            const optionsDraw = {
                map: map, // Drawing Manager로 그리기 요소를 그릴 map 객체입니다
                drawingMode: [ // Drawing Manager로 제공할 그리기 요소 모드입니다
                    kakao.maps.drawing.OverlayType.MARKER,
                    kakao.maps.drawing.OverlayType.POLYLINE,
                    kakao.maps.drawing.OverlayType.RECTANGLE,
                    kakao.maps.drawing.OverlayType.CIRCLE,
                    kakao.maps.drawing.OverlayType.POLYGON
                ],
                // 사용자에게 제공할 그리기 가이드 툴팁입니다
                // 사용자에게 도형을 그릴때, 드래그할때, 수정할때 가이드 툴팁을 표시하도록 설정합니다
                guideTooltip: ['draw', 'drag', 'edit'],
                markerOptions: { // 마커 옵션입니다 
                    draggable: true, // 마커를 그리고 나서 드래그 가능하게 합니다 
                    removable: true // 마커를 삭제 할 수 있도록 x 버튼이 표시됩니다  
                },
                polylineOptions: { // 선 옵션입니다
                    draggable: true, // 그린 후 드래그가 가능하도록 설정합니다
                    removable: true, // 그린 후 삭제 할 수 있도록 x 버튼이 표시됩니다
                    editable: true, // 그린 후 수정할 수 있도록 설정합니다 
                    strokeColor: '#39f', // 선 색
                    hintStrokeStyle: 'dash', // 그리중 마우스를 따라다니는 보조선의 선 스타일
                    hintStrokeOpacity: 0.5  // 그리중 마우스를 따라다니는 보조선의 투명도
                },
            }

            var manager = new kakao.maps.drawing.DrawingManager(optionsDraw);
            setMaps(map);




            const markers = [];

            var selectedMarker = null;

            if (markerStop.length > 0) {

                // var imageSrc = "https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/markerStar.png";


                // for (let j = 0; j < routeStop.length; j++) {


                //     if (routeStop[j].stop_latitude === resultDelete[i][0].line_latitude &&
                //         routeStop[j].stop_longitude === resultDelete[i][0].line_longitude
                //     ) {
                //     }}


                for (var i = 0; i < markerStop.length; i++) {

                    if (i === markerStop.length - 1) {
                        if (markerStop[0].line_longitude === markerStop[i].line_longitude) {
                            break;
                        }


                    }

                    // 마커 이미지의 이미지 크기 입니다
                    var imageSize = new kakao.maps.Size(30, 30);

                    // 마커 이미지를 생성합니다    
                    var markerImage = new kakao.maps.MarkerImage(markerStop[i].rs_stop_essential ? mColor : m, imageSize);

                    // 마커를 생성합니다
                    var marker = new kakao.maps.Marker({
                        map: map, // 마커를 표시할 지도
                        position: new kakao.maps.LatLng(markerStop[i].line_latitude, markerStop[i].line_longitude), // 마커를 표시할 위치
                        title: markerStop[i].no, // 마커의 타이틀, 마커에 마우스를 올리면 타이틀이 표시됩니다
                        image: markerImage // 마커 이미지 

                    });


                    var infowindow = new kakao.maps.InfoWindow({
                        content: markerStop[i].stop_name // 인포윈도우에 표시할 내용
                    });


                    markers.push(marker);
                    // 마커에 mouseover 이벤트와 mouseout 이벤트를 등록합니다
                    // 이벤트 리스너로는 클로저를 만들어 등록합니다 
                    // for문에서 클로저를 만들어 주지 않으면 마지막 마커에만 이벤트가 등록됩니다
                    kakao.maps.event.addListener(marker, 'mouseover', makeOverListener(map, marker, infowindow));
                    kakao.maps.event.addListener(marker, 'mouseout', makeOutListener(infowindow))

                    kakao.maps.event.addListener(marker, 'rightclick', makeRightClick(map, marker))





                }

                manager.addListener('remove', makeDrawCancel(map, markers,))


                // marker.setMap(map)
            }
            function clickSave(getArray, manager, title) {
                return function () {

                    let mapPoint = manager.getData()[kakao.maps.drawing.OverlayType.POLYLINE][0].points
                    if (getArray[0].line_longitude === mapPoint[0].x && getArray[getArray.length - 1].line_latitude === mapPoint[mapPoint.length - 1].y


                    ) {

                        let insertValue = "";

                        for (let i = 0; i < mapPoint.length; i++) {
                            if (i === 0) {
                                insertValue += `('${mapPoint[i].x}','${mapPoint[i].y}','${title}','${getArray[0].stop_number === getArray[0].detail_stop_no_one ? getArray[0].detail_stop_no_one : getArray[0].detail_stop_no_two}'),`
                            } else if (i === mapPoint.length - 1) {
                                insertValue += `('${mapPoint[i].x}','${mapPoint[i].y}','${title}','${getArray[0].detail_stop_no_one === getArray[0].detail_stop_no_one ? getArray[0].detail_stop_no_two : getArray[0].detail_stop_no_one}'),`
                            } else {
                                insertValue += `('${mapPoint[i].x}','${mapPoint[i].y}','${title}','0'),`
                            }

                        }


                        let sendData = {
                            no: title,

                            insertValue: insertValue.slice(0, -1)
                        }
                        CommonAxios(
                            process.env.REACT_APP_HOSTDONAME + "/api/admin/update_line",
                            sendData,
                            function (result) {
                                if (result.result) {


                                    recentData();
                                    window.alert("저장 완료");

                                }



                            })



                        // console.log("성공")
                    } else {




                    }
                }
            }
            function makeDrawCancel(map, marker) {

                return function () {
                    saveBtn.style.display = "none";
                    selectedMarker = null;

                    polyline = new kakao.maps.Polyline({
                        path: kakaoLine, // 선을 구성하는 좌표배열 입니다
                        strokeWeight: 10, // 선의 두께 입니다
                        strokeColor: '#FFAE00', // 선의 색깔입니다
                        strokeOpacity: 1, // 선의 불투명도 입니다 1에서 0 사이의 값이며 0에 가까울수록 투명합니다
                        strokeStyle: 'solid',

                    });

                    // 지도에 선을 표시합니다 
                    polyline.setMap(map);
                }
            }
            function makeRightClick(map, marker) {

                return function () {

                    saveBtn.style.display = "block";
                    if (selectedMarker === null) {
                        selectedMarker = marker;
                        polyline.setMap(null);

                        // setSaveShow(true);

                        let markerPo = selectedMarker.getPosition();


                        let getArray = routeLine.filter(el => el.no === Number(selectedMarker.getTitle())).sort((a, b) => Number(b.linNo) - Number(a.linNo));


                        let pathArr = [];
                        if (getArray.length > 0) {

                            if (getArray[0].line_longitude == markerPo.La) {

                                // console.log(getArray.reverse());
                                getArray.map((item) => {

                                    pathArr.push(new kakao.maps.LatLng(item.line_latitude, item.line_longitude))
                                });


                                if (pathArr) {


                                    manager.put(kakao.maps.drawing.OverlayType.POLYLINE, pathArr);
                                    setManagers(manager);
                                    // kakao.maps.event.addListener(manager, 'drawend', function (mouseEvent) {
                                    //     console.log("취소");
                                    // })
                                    manager.addListener('draw', function (data) {

                                    });
                                }

                            } else {

                                getArray.reverse().map((item) => {

                                    pathArr.push(new kakao.maps.LatLng(item.line_latitude, item.line_longitude))
                                });

                                if (pathArr) {


                                    manager.put(kakao.maps.drawing.OverlayType.POLYLINE, pathArr);
                                    setManagers(manager);

                                    // kakao.maps.event.addListener(manager, 'drawend', function (mouseEvent) {
                                    //     console.log("취소");
                                    // })
                                    manager.addListener('draw', function (data) {

                                    });
                                }

                            }


                            // setSaveShow(true);
                        }


                        saveBtn.addEventListener("click", clickSave(getArray, manager, selectedMarker.getTitle()))


                    } else {
                        polyline.setMap(null);
                        alert("저장을 하고 진행해주세요.");
                        // setSelectMarker(marker);
                    }


                }

            }

            // 인포윈도우를 표시하는 클로저를 만드는 함수입니다 
            function makeOverListener(map, marker, infowindow) {


                return function () {
                    infowindow.open(map, marker);
                };
            }

            // 인포윈도우를 닫는 클로저를 만드는 함수입니다 
            function makeOutListener(infowindow) {
                return function () {
                    infowindow.close();
                };
            }

            var polyline = new kakao.maps.Polyline({
                path: kakaoLine, // 선을 구성하는 좌표배열 입니다
                strokeWeight: 10, // 선의 두께 입니다
                strokeColor: '#FFAE00', // 선의 색깔입니다
                strokeOpacity: 1, // 선의 불투명도 입니다 1에서 0 사이의 값이며 0에 가까울수록 투명합니다
                strokeStyle: 'solid',

            });

            // 지도에 선을 표시합니다 
            polyline.setMap(map);



            // var markerPosition = new kakao.maps.LatLng(36.63845393371924, 127.44469182843764);

            // // 마커를 생성합니다
            // var marker = new kakao.maps.Marker({
            //     position: markerPosition
            // });

            // // 마커가 지도 위에 표시되도록 설정합니다
            // marker.setMap(map);

            // var markerPosition2 = new kakao.maps.LatLng(36.637626978525084, 127.44231658794477);

            // // 마커를 생성합니다
            // var marker2 = new kakao.maps.Marker({
            //     position: markerPosition2
            // });

            // // 마커가 지도 위에 표시되도록 설정합니다
            // marker2.setMap(map);
            // // 위에 작성한 옵션으로 Drawing Manager를 생성합니다
            // let manager = new kakao.maps.drawing.DrawingManager(options2);
            // setManagers(manager);

        }, [])


        function drawPolyline(lines) {
            var len = lines.length, i = 0;

            for (; i < len; i++) {
                var path = pointsToPath(lines[i].points);
                var style = lines[i].options;
                var polyline = new kakao.maps.Polyline({
                    map: maps1,
                    path: path,
                    strokeColor: style.strokeColor,
                    strokeOpacity: style.strokeOpacity,
                    strokeStyle: style.strokeStyle,
                    strokeWeight: style.strokeWeight
                });

                // overlays.push(polyline);
            }
        }
        function pointsToPath(points) {
            var len = points.length,
                path = [],
                i = 0;

            for (; i < len; i++) {
                var latlng = new kakao.maps.LatLng(points[i].y, points[i].x);
                path.push(latlng);
            }

            return path;
        }




        const btn_save = () => {
            var data = managers.getData();
            // drawPolyline(data[kakao.maps.drawing.OverlayType.POLYLINE]);

        }

        const warring = () => {
            window.alert("정류장에서 마우스 우측 버튼을 클릭하세요.")
        }
        return (<>

            <div id="map" className={line.line_map}  >
                <div className={line.line_map_btn}>
                    <div className={line.line_map_draw} onClick={warring}><p>경로그리기</p></div>

                    <div id="save" className={line.line_map_save} ><p>경로 저장</p></div>

                </div>
            </div>


        </>)
    }

    const click_view = (fliterView) => {

        setFilterView(!fliterView);

    }

    const filterVal = (item) => {
        setFilterValue(item);
        setFilterView(false);
        if (item === "이름순") {
            setSearchStop(searchStop.sort((a, b) => a.stop_name.toLowerCase() < b.stop_name.toLowerCase() ? -1 : 1))
        } else {
            setSearchStop(searchStop.sort((a, b) => a.stop_number.toLowerCase() < b.stop_number.toLowerCase() ? -1 : 1))
        }
    }
    const [searchValue, setSearchValue] = useState("");
    const [searchData, setSearchData] = useState([]);
    const change_search = useCallback((e) => {
        setSearchValue(e.target.value);
    }, [])

    const search_btn = () => {


        setSearchData(searchStop.filter((item) => item.stop_name.includes(searchValue) || item.stop_number.includes(searchValue)))

    }
    const modal_close = () => {
        setView(false)
        setSearchData([]);
        setSearchValue("");
    }


    const excelDown = async () => {
        try {

            const wb = XLSX.utils.book_new();
            const headerStyle = {
                font: { bold: true, color: { rgb: '000000' }, name: '함초롱바탕', sz: 13 },
                // fill: { fgColor: { rgb: 'BC8F8F' } },
                alignment: { horizontal: 'center', vertical: 'center' },
                border: { left: { style: 'thin', color: { auto: 1 } }, right: { style: 'thin', color: { auto: 1 } }, top: { style: 'thin', color: { auto: 1 } }, bottom: { style: 'thin', color: { auto: 1 } } }
            };

            const dataStyle = {
                font: { color: { rgb: '000000' }, name: '함초롱바탕', sz: 11 },
                fill: { fgColor: { rgb: 'FFFAFA' } },
                alignment: { horizontal: 'center', vertical: 'center' },
                border: { left: { style: 'thin', color: { auto: 1 } }, right: { style: 'thin', color: { auto: 1 } }, top: { style: 'thin', color: { auto: 1 } }, bottom: { style: 'thin', color: { auto: 1 } } }
            };
            const colWidths = [40, 130, 80, 80, 80, 130, 80, 80, 80];
            const cols = colWidths.map(width => ({ wpx: width }));
            const headerRow = [
                { v: 'No', t: 's', s: headerStyle },
                { v: '정류장명', t: 's', s: headerStyle },
                { v: '정류장번호', t: 's', s: headerStyle },
                { v: '위도', t: 's', s: headerStyle },
                { v: '경도', t: 's', s: headerStyle },
                { v: '방면정보', t: 's', s: headerStyle },
                { v: '화상연결', t: 's', s: headerStyle },
                { v: '필수가변', t: 's', s: headerStyle },
                { v: '소요시간', t: 's', s: headerStyle },
            ];

            const dataRows = routeStop.map(emp => [
                { v: emp.ROWNUM, t: 's', s: dataStyle },  // 사원번호
                { v: emp.stop_name, t: 's', s: dataStyle },  // 사원명
                { v: emp.stop_number, t: 's', s: dataStyle },  // 현황
                { v: emp.stop_latitude, t: 's', s: dataStyle },  // 직급
                { v: emp.stop_longitude, t: 's', s: dataStyle },  // 전화번호
                { v: emp.stop_direction, t: 's', s: dataStyle },
                { v: emp.stop_connection, t: 's', s: dataStyle },
                { v: emp.rs_stop_essential, t: 's', s: dataStyle },
                { v: emp.rs_time, t: 's', s: dataStyle },
            ]);
            const rows = [headerRow, ...dataRows];
            const ws = XLSX.utils.aoa_to_sheet(rows);

            // cols 속성 적용
            ws['!cols'] = cols;

            // workbook에 추가
            XLSX.utils.book_append_sheet(wb, ws, '사원 목록');

            // 파일 다운로드
            XLSX.writeFile(wb, 'employee_list.xlsx');
        } catch (e) {

        } finally { }
    }


    const InsertData = (e) => {
        const file = e.target.files[0];

        const reader = new FileReader();
        reader.onload = (event) => {
            const binaryString = event.target.result;
            const workbook = XLSX.read(binaryString, { type: 'binary' });

            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];

            const dataArray = XLSX.utils.sheet_to_json(sheet, { header: 1 });

            // console.log(dataArray)
        }
        reader.readAsBinaryString(file);
    }
    return (
        <>
            {view && <div className={line.line_map_modal}>
                <div className={line.line_modal_inner}>
                    <div className={line.line_modal_title}>
                        <span>정류장 목록</span>
                    </div>
                    <div className={line.line_modal_search}>
                        <div className={line.line_search_box}>
                            <input type="text" value={searchValue} placeholder="정류장명,번호 검색" onChange={change_search} />
                            <button onClick={search_btn}>검색</button>
                        </div>
                        <div className={line.line_search_filter}>
                            <div onClick={() => click_view(fliterView)}><span>{filterValue}</span></div>
                            <ul className={fliterView ? `${line.line_search_ul} ${line.line_filter_view}` : line.line_search_ul}>
                                <li onClick={() => filterVal("정류장번호순")}>정류장번호순</li>
                                <li onClick={() => filterVal("이름순")} >이름순</li>
                            </ul>
                        </div>
                    </div>
                    <div className={line.line_modal_content}>
                        <div className={line.line_modal_list}>
                            {searchData.length === 0 && searchStop && searchStop.map((data, index) => {
                                return <SearchItem item={data} index={index} />
                            })}
                            {searchData.length > 0 && searchData.map((data, index) => {
                                return <SearchItem item={data} index={index} />
                            })}
                        </div>
                    </div>
                    <div className={line.line_modal_btn}>
                        <button onClick={
                            () => modal_close()}>닫기</button>
                        <button onClick={insertStop}>저장</button>
                    </div>
                </div>
            </div>}
            <div className={line.line_map_wrap}>
                {markerStop && <MapCompents markerStop={markerStop} />}
                <div className={line.line_map_info} >
                    <div className={line.line_info_top}>
                        <div className={line.line_info_title}>
                            정류장 관리
                        </div>
                        <div className={line.line_info_menu}>
                            <ul className={line.info_menu_ul}>
                                <li onClick={excelDown}><FaFileExcel /><span>엑셀다운로드</span></li>
                                <li>
                                    <label htmlFor="file"><span>
                                        엑셀업로드</span>
                                    </label></li>


                                <input style={{ display: "none" }} type="file" id="file" name="file" className="admins_float_btn"
                                    onChange={InsertData}
                                />
                                <li style={{ cursor: "pointer" }} onClick={() => setView(true)}><span>관리</span></li>
                            </ul>
                        </div>
                    </div>
                    <div className={line.line_info_content}>
                        <div className={line.line_info_inner}>
                            <table className={line.line_info_table}>
                                <thead className={line.info_table_th}>
                                    <tr>
                                        <th>경로순서</th>
                                        <th>정류장명</th>
                                        <th>정류장번호</th>
                                        <th>방면정보</th>
                                        <th>소요시간
                                            <br></br>(분)</th>
                                        <th>필수/가변</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody className={line.info_table_tbody}>
                                    {routeStop && routeStop.map((data, index) => {
                                        return <Tbdata data={data} index={index} />
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div>


                </div>

            </div></>)
}

export default RouteMapWrap